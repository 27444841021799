import React from "react";
import ImageText from "./ImageText"
import ImageLarge from "./ImageLarge"
import ImageLargeSmall from "./ImageLargeSmall"

function ContentBlocks({blocks}) {
  let iteratorSmallLarge = 1;
  const contentBlocks = (blocks || [])
    .map((c, i) => {
      let el = null;
      switch (c._type) {
        case "contentImageText":
          iteratorSmallLarge++
          el = <ImageText {...c} iterator={iteratorSmallLarge} key={i} />;
          break;
        case "contentImageLarge":
          el = <ImageLarge {...c} key={i} />;
          break;
        case "contentImageLargeSmall":
          iteratorSmallLarge++
          el = <ImageLargeSmall {...c} iterator={iteratorSmallLarge} key={i} />;
          break;
        default:
          el = "";
      }
      return el;
    });

  return (
    <div>
      {contentBlocks}
    </div>
  )
}

export default ContentBlocks