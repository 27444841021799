import React from "react";
import Img from "gatsby-image"
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import clientConfig from "/client-config";

function ContentImageLarge(props) {
  const fluidProps = props.image && getFluidGatsbyImage(props.image.asset, {maxWidth: 1600}, clientConfig.sanity)
  return (
    <div key={props._id} className="block content-img">
      {props.image &&
        <Img className="portfolio__image" fluid={fluidProps} alt={props.imageAlt} />
      }
    </div>
  )
}

export default ContentImageLarge

